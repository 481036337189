import React from "react";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { graphql, useStaticQuery } from "gatsby";

import { container, content, title,subTitle, item } from './production-steps-slider.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { IProductionStep } from "../../models/production-step.model";
import { ImageDataLike } from "../../models/image-data-like.model";

import Title from "../atoms/title";
import ProductionStepItem from "../molecules/production-step-item";
import Slider from "../hoc/slider";
import SharpArrowButton from "../atoms/sharp-arrow-button";


interface IProductionStepsProps {
    className?: string;
}

interface IProductionStepsQuery {
    allFile: { edges: { node: ImageDataLike }[] }
}

const ProductionStepsSlider: React.FC<IProductionStepsProps> = ({
    className = '',
}) => {
    const { allFile }: IProductionStepsQuery = useStaticQuery(query)
    const { t } = useI18next();

    const images = allFile.edges.map(({ node }) => node);

    return (
        <div className={`${grid} ${container} ${className}`}>
            <div className={content}>
                <Title>
                    <p className={title}>
                    {t('production.title.meet').toUpperCase()}
                    </p>
                    <p className={subTitle}>
                    {t('production.subtitle.meet').toUpperCase()}
                    </p>
                </Title>
                <Slider ButtonComponent={SharpArrowButton} isSliderFlexbox={true}>
                    {images.map((image, index) => {
                        const productionStep: IProductionStep = {
                            order: `${index + 1}`,
                            title:t(`production.step.description.title.${index}`),
                            description: t(`production.step.description.${index}`),
                            image: image,
                        }
                        return (
                            <li className={item} key={`step-${index}`}>
                                <ProductionStepItem
                                    productionStep={productionStep}
                                />
                            </li>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

const query = graphql`
    query {
        allFile (
            filter: {relativePath: {in: [
                "production-step-01.png",
                "production-step-02.png",
                "production-step-03.png",
                "production-step-04.png"
            ]}}
            sort: {fields: relativePath, order: ASC}
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData (
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`;

export default ProductionStepsSlider;