import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    production,
    squares,
} from './index.module.scss';
import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ImageDataLike } from '../models/image-data-like.model';
import { getNodes } from '../utils/get-nodes';
import { getSquareItems } from '../utils/get-square-items';

import MainLayout from '../layouts/main-layout';
import ProductionStepsSlider from '../components/organisms/production-steps-slider';
import SquareList from '../components/organisms/square-list';

interface IIndexProps {
    readonly data: {
        page: IPage | null;
        allSquareImg: IQueryAllResult<ImageDataLike>;
    };
}

const squareLinks = [
    'soplice-tradycyjnie-owocowe',
    'soplice-cytrynowe',
    'soplice-deserowe',
    'soplica-gorzka',
    'soplica-szlachetna-premium',
    'soplica-szlachetna',
    'spirytus-nalewkowy',
    'soplica-staropolska',
];

const IndexPage: React.FC<IIndexProps> = ({ data }) => {
    const { page, allSquareImg } = data;
    const { t, language } = useI18next();
    const squareImages = getNodes(allSquareImg);
    const { introTitle, introContent } = page || {};

    return (
        <MainLayout page={page} className={layout} companyHistoryTitle={introTitle} companyHistoryContent={introContent} showRecipesSlider={true} siteKey="lemon">
            <ProductionStepsSlider className={production} />
            <SquareList
                className={squares}
                isBoldReversed={language === 'en'}
                items={getSquareItems({
                    images: squareImages,
                    links: squareLinks,
                    translationKey: 'home.square',
                    t,
                })}
                title={t('home.squares.discover')}
                subtitle={t("home.squares.soplicas")}
                imgBig={true}
            />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
        
        allSquareImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "square-home-01.png"
                        "square-home-02.png"
                        "square-home-03.png"
                        "square-home-04.png"
                        "square-home-05.png"
                        "square-home-06.png"
                        "square-home-07.png"
                        "square-home-08.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 99)
                    }
                }
            }
        }
    }
`;

export default IndexPage;
