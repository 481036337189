import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import {
    container,
    imgBox,
    ratio,
    content,
    titleOrder,
    stepTitle,
} from './production-step-item.module.scss';
import { IProductionStep } from '../../models/production-step.model';

import RatioImage from '../atoms/ratio-image';

interface IProductionStepItemProps {
    className?: string;
    productionStep: IProductionStep;
    TitleTag?: React.ElementType;
    hasArrow?: boolean;
}

const ProductionStepItem: React.FC<IProductionStepItemProps> = ({
    className = '',
    TitleTag = 'h3',
    productionStep,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <div className={imgBox}>
                <RatioImage
                    image={getImage(productionStep.image)}
                    ratioClass={ratio}
                    objectFit="none"
                />
            </div>
            <div className={content}>
                <TitleTag className={titleOrder}>{productionStep.order}</TitleTag>
                <p className={stepTitle}>{productionStep.title}</p>
                <p>{productionStep.description}</p>
            </div>
        </div>
    );
};

export default ProductionStepItem;
