import React from 'react';

import { button } from './sharp-arrow-button.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-black.svg';

interface ISharpArrowButtonProps {
    className?: string;
    rotate?: number;
    onClick?(): any;
    ariaLabel?: string;
}

const SharpArrowButton = React.forwardRef<HTMLButtonElement, ISharpArrowButtonProps>((props, ref) => {
    const { className = '', rotate = 0, onClick = () => {}, ariaLabel = '' } = props;
    return (
        <button
            ref={ref}
            className={`${button} ${className}`}
            aria-label={ariaLabel}
            onClick={onClick}
        >
            <ArrowIcon style={{ transform: `rotate(${rotate}deg)` }} />
        </button>
    );
});

export default SharpArrowButton;
